import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
    bannerLoading:false,
    bannerList:[],
    bannererr:''
}

export const fetchBanners = createAsyncThunk('get/banners',() => {
    return axios.get(`https://api.designduty.in/get-banners?api=get-banners`).then(res => res.data);
});

const bannerSlice = createSlice({
    name:'banners',
    initialState,
    extraReducers:builders => {
        builders.addCase(fetchBanners.pending, state => {
            state.bannerLoading = true
        })
        builders.addCase(fetchBanners.fulfilled, (state, action) => {
            console.log(action.payload,"masss");
            if(action.payload.status === 200){
                state.bannerLoading = false;
                state.bannerList = action.payload.list;
                state.bannererr = "";
                
            }else{
                state.bannerLoading = false;
                state.bannerList = [];
                state.bannererr = action.payload.msg
            }
        })
        builders.addCase(fetchBanners.rejected, (state, action) => {
            state.bannerLoading = false;
            state.bannerList = action.payload;
            state.bannererr = action.error.message
        });
    }
})

export default bannerSlice.reducer;