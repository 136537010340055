import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchProducts = createAsyncThunk('get/products',(action='ALL') => {
    return axios.get(`https://api.designduty.in/get-products?api=get-products&action=ACTIVE`).then(res => res.data);
});

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        productLoading:true
    },
    extraReducers:builders => {
        builders.addCase(fetchProducts.pending, state => {
            state.productLoading = true
        })
        builders.addCase(fetchProducts.fulfilled, (state, action) => {
            if(action.payload.status === 200){
                state.productLoading = false;
                state.products= action.payload.list;
            }else{
                state.productLoading = false;
                state.products = [];
            }
        })
        builders.addCase(fetchProducts.rejected, (state, action) => {
            state.productLoading = false;
            state.products = [];
        });
    }
});

export default productSlice.reducer;
